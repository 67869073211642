
const dzsap_generate_keyboard_controls = function(){
  var keyboard_controls = {
    'play_trigger_step_back':'off'
    ,'step_back_amount':'5'
    ,'step_back':'37'
    ,'step_forward':'39'
    ,'sync_players_goto_next':''
    ,'sync_players_goto_prev':''
    ,'pause_play':'32'
    ,'show_tooltips':'off'
  }



  if(window.dzsap_keyboard_controls){
    // console.log('keyboard_controls - ',keyboard_controls);
    // console.log('window.dzsap_keyboard_controls - ',window.dzsap_keyboard_controls);
    keyboard_controls = jQuery.extend(keyboard_controls,window.dzsap_keyboard_controls);
  }

  keyboard_controls.step_back_amount = Number(keyboard_controls.step_back_amount);

  return keyboard_controls;
};


function formatTime(arg) {
  //formats the time
  var s = Math.round(arg);
  var m = 0;
  var h = 0;
  if (s > 0) {
    while (s > 3599 && s < 3000000 && isFinite(s)) {
      h++;
      s -= 3600;
    }
    while (s > 59 && s < 3000000 && isFinite(s)) {
      m++;
      s -= 60;
    }
    if(h){

      return String((h < 10 ? "0" : "") + h + ":" + String((m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s));
    }
    return String((m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s);
  } else {
    return "00:00";
  }
}

function can_history_api() {
  return !!(window.history && history.pushState);
}
function dzs_clean_string(arg) {

  if (arg) {

    arg = arg.replace(/[^A-Za-z0-9\-]/g, '');
    //console.log(arg);
    arg = arg.replace(/\./g, '');
    return arg;
  }

  return '';


  //console.log(arg);


}



function get_query_arg(purl, key) {
  if (purl) {
    // console.log('purl - ',purl);
    if (String(purl).indexOf(key + '=') > -1) {
      //faconsole.log('testtt');
      var regexS = "[?&]" + key + "=.+";
      var regex = new RegExp(regexS);
      var regtest = regex.exec(purl);
      //console.log(regtest);

      if (regtest != null) {
        var splitterS = regtest[0];
        if (splitterS.indexOf('&') > -1) {
          var aux = splitterS.split('&');
          splitterS = aux[1];
        }
        //console.log(splitterS);
        var splitter = splitterS.split('=');
        //console.log(splitter[1]);
        //var tempNr = ;

        return splitter[1];

      }
      //$('.zoombox').eq
    }

  } else {
    console.log('purl not found - ', purl);
  }
}

function add_query_arg(purl, key, value) {
  // -- key and value must be unescaped for uri
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);

  if (!(purl)) {
    purl = '';
  }
  var s = purl;
  var pair = key + "=" + value;

  var r = new RegExp("(&|\\?)" + key + "=[^\&]*");

  s = s.replace(r, "$1" + pair);
  //console.log(s, pair);
  if (s.indexOf(key + '=') > -1) {


  } else {
    if (s.indexOf('?') > -1) {
      s += '&' + pair;
    } else {
      s += '?' + pair;
    }
  }
  //if(!RegExp.$1) {s += (s.length>0 ? '&' : '?') + kvp;};


  //if value NaN we remove this field from the url
  if (value == 'NaN') {
    var regex_attr = new RegExp('[\?|\&]' + key + '=' + value);
    s = s.replace(regex_attr, '');


    if (s.indexOf('?') == -1 && s.indexOf('&') > -1) {
      s = s.replace('&', '?');
    }
  }

  return s;
}


function dzsap_is_mobile() {

  // return true;
  return is_ios() || is_android_good();
}

function is_ios() {
  // return true;
  return ((navigator.platform.indexOf("iPhone") != -1) || (navigator.platform.indexOf("iPod") != -1) || (navigator.platform.indexOf("iPad") != -1));
}


function can_canvas() {
  // check if we have canvas support
  var oCanvas = document.createElement("canvas");
  if (oCanvas.getContext("2d")) {
    return true;
  }
  return false;
}

function is_android() {
  return is_android_good();
}
function select_all(el) {
  if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.selection != "undefined" && typeof document.body.createTextRange != "undefined") {
    var textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.select();
  }
}

function is_android_good() {
  //return false;
  //return true;
  var ua = navigator.userAgent.toLowerCase();

  //console.log('ua - ',ua);
  return (ua.indexOf("android") > -1);
}
function htmlEncode(arg) {
  return jQuery('<div/>').text(arg).html();
}
function dzsap_generate_keyboard_tooltip(keyboard_controls, lab) {


  // console.log('keyboard_controls - ',keyboard_controls,lab,keyboard_controls.lab);
  var fout = '<span class="dzstooltip arrow-from-start transition-slidein arrow-bottom skin-black" style="width: auto;  white-space:nowrap;">' + 'Shortcut' + ': ' + keyboard_controls[lab] + '</span>';
  // left: 5px;
  fout = fout.replace('32', 'space');
  fout = fout.replace('27', 'escape');

  return fout;


}





var MD5 = function (e) {
  function g(a, d) {
    var b = a & 2147483648;
    var c = d & 2147483648;
    var e = a & 1073741824;
    var f = d & 1073741824;
    var p = (a & 1073741823) + (d & 1073741823);
    return e & f ? p ^ 2147483648 ^ b ^ c : e | f ? p & 1073741824 ? p ^ 3221225472 ^ b ^ c : p ^ 1073741824 ^ b ^ c : p ^ b ^ c
  }

  function h(b, c, a, d, e, f, n) {
    b = g(b, g(g(c & a | ~c & d, e), n));
    return g(b << f | b >>> 32 - f, c)
  }

  function k(b, c, a, d, e, f, n) {
    b = g(b, g(g(c & d | a & ~d, e), n));
    return g(b << f | b >>> 32 - f, c)
  }

  function l(b, c, d, a, e, f, n) {
    b = g(b, g(g(c ^ d ^ a, e), n));
    return g(b << f | b >>> 32 - f, c)
  }

  function m(b, c, d, a, e, f, n) {
    b = g(b, g(g(d ^
      (c | ~a), e), n));
    return g(b << f | b >>> 32 - f, c)
  }

  function q(b) {
    var c = "", d;
    for (d = 0; 3 >= d; d++) {
      var a = b >>> 8 * d & 255;
      a = "0" + a.toString(16);
      c += a.substr(a.length - 2, 2)
    }
    return c
  }

  var f = [];
  e = function (b) {
    b = b.replace(/\r\n/g, "\n");
    for (var c = "", d = 0; d < b.length; d++) {
      var a = b.charCodeAt(d);
      128 > a ? c += String.fromCharCode(a) : (127 < a && 2048 > a ? c += String.fromCharCode(a >> 6 | 192) : (c += String.fromCharCode(a >> 12 | 224), c += String.fromCharCode(a >> 6 & 63 | 128)), c += String.fromCharCode(a & 63 | 128))
    }
    return c
  }(e);
  f = function (b) {
    var c = b.length;
    var a = c + 8;
    for (var d =
      16 * ((a - a % 64) / 64 + 1), e = Array(d - 1), f, g = 0; g < c;) a = (g - g % 4) / 4, f = g % 4 * 8, e[a] |= b.charCodeAt(g) << f, g++;
    a = (g - g % 4) / 4;
    e[a] |= 128 << g % 4 * 8;
    e[d - 2] = c << 3;
    e[d - 1] = c >>> 29;
    return e
  }(e);
  var a = 1732584193;
  var d = 4023233417;
  var b = 2562383102;
  var c = 271733878;
  for (e = 0; e < f.length; e += 16) {
    var r = a;
    var t = d;
    var u = b;
    var v = c;
    a = h(a, d, b, c, f[e + 0], 7, 3614090360);
    c = h(c, a, d, b, f[e + 1], 12, 3905402710);
    b = h(b, c, a, d, f[e + 2], 17, 606105819);
    d = h(d, b, c, a, f[e + 3], 22, 3250441966);
    a = h(a, d, b, c, f[e + 4], 7, 4118548399);
    c = h(c, a, d, b, f[e + 5], 12, 1200080426);
    b = h(b, c, a, d, f[e +
    6], 17, 2821735955);
    d = h(d, b, c, a, f[e + 7], 22, 4249261313);
    a = h(a, d, b, c, f[e + 8], 7, 1770035416);
    c = h(c, a, d, b, f[e + 9], 12, 2336552879);
    b = h(b, c, a, d, f[e + 10], 17, 4294925233);
    d = h(d, b, c, a, f[e + 11], 22, 2304563134);
    a = h(a, d, b, c, f[e + 12], 7, 1804603682);
    c = h(c, a, d, b, f[e + 13], 12, 4254626195);
    b = h(b, c, a, d, f[e + 14], 17, 2792965006);
    d = h(d, b, c, a, f[e + 15], 22, 1236535329);
    a = k(a, d, b, c, f[e + 1], 5, 4129170786);
    c = k(c, a, d, b, f[e + 6], 9, 3225465664);
    b = k(b, c, a, d, f[e + 11], 14, 643717713);
    d = k(d, b, c, a, f[e + 0], 20, 3921069994);
    a = k(a, d, b, c, f[e + 5], 5, 3593408605);
    c = k(c,
      a, d, b, f[e + 10], 9, 38016083);
    b = k(b, c, a, d, f[e + 15], 14, 3634488961);
    d = k(d, b, c, a, f[e + 4], 20, 3889429448);
    a = k(a, d, b, c, f[e + 9], 5, 568446438);
    c = k(c, a, d, b, f[e + 14], 9, 3275163606);
    b = k(b, c, a, d, f[e + 3], 14, 4107603335);
    d = k(d, b, c, a, f[e + 8], 20, 1163531501);
    a = k(a, d, b, c, f[e + 13], 5, 2850285829);
    c = k(c, a, d, b, f[e + 2], 9, 4243563512);
    b = k(b, c, a, d, f[e + 7], 14, 1735328473);
    d = k(d, b, c, a, f[e + 12], 20, 2368359562);
    a = l(a, d, b, c, f[e + 5], 4, 4294588738);
    c = l(c, a, d, b, f[e + 8], 11, 2272392833);
    b = l(b, c, a, d, f[e + 11], 16, 1839030562);
    d = l(d, b, c, a, f[e + 14], 23, 4259657740);
    a = l(a, d, b, c, f[e + 1], 4, 2763975236);
    c = l(c, a, d, b, f[e + 4], 11, 1272893353);
    b = l(b, c, a, d, f[e + 7], 16, 4139469664);
    d = l(d, b, c, a, f[e + 10], 23, 3200236656);
    a = l(a, d, b, c, f[e + 13], 4, 681279174);
    c = l(c, a, d, b, f[e + 0], 11, 3936430074);
    b = l(b, c, a, d, f[e + 3], 16, 3572445317);
    d = l(d, b, c, a, f[e + 6], 23, 76029189);
    a = l(a, d, b, c, f[e + 9], 4, 3654602809);
    c = l(c, a, d, b, f[e + 12], 11, 3873151461);
    b = l(b, c, a, d, f[e + 15], 16, 530742520);
    d = l(d, b, c, a, f[e + 2], 23, 3299628645);
    a = m(a, d, b, c, f[e + 0], 6, 4096336452);
    c = m(c, a, d, b, f[e + 7], 10, 1126891415);
    b = m(b, c, a, d, f[e + 14], 15, 2878612391);
    d = m(d, b, c, a, f[e + 5], 21, 4237533241);
    a = m(a, d, b, c, f[e + 12], 6, 1700485571);
    c = m(c, a, d, b, f[e + 3], 10, 2399980690);
    b = m(b, c, a, d, f[e + 10], 15, 4293915773);
    d = m(d, b, c, a, f[e + 1], 21, 2240044497);
    a = m(a, d, b, c, f[e + 8], 6, 1873313359);
    c = m(c, a, d, b, f[e + 15], 10, 4264355552);
    b = m(b, c, a, d, f[e + 6], 15, 2734768916);
    d = m(d, b, c, a, f[e + 13], 21, 1309151649);
    a = m(a, d, b, c, f[e + 4], 6, 4149444226);
    c = m(c, a, d, b, f[e + 11], 10, 3174756917);
    b = m(b, c, a, d, f[e + 2], 15, 718787259);
    d = m(d, b, c, a, f[e + 9], 21, 3951481745);
    a = g(a, r);
    d = g(d, t);
    b = g(b, u);
    c = g(c, v)
  }
  return (q(a) + q(d) + q(b) +
    q(c)).toLowerCase()
};
exports.MD5 = MD5;
exports.select_all = select_all;
exports.dzsap_generate_keyboard_tooltip = dzsap_generate_keyboard_tooltip;
exports.htmlEncode = htmlEncode;
exports.dzsap_is_mobile = dzsap_is_mobile;
exports.is_ios = is_ios;
exports.is_android = is_android;
exports.is_android_good = is_android_good;
exports.get_query_arg = get_query_arg;
exports.add_query_arg = add_query_arg;
exports.can_history_api = can_history_api;
exports.dzs_clean_string = dzs_clean_string;
exports.formatTime = formatTime;
exports.can_canvas = can_canvas;


exports.dzsap_generate_keyboard_controls = dzsap_generate_keyboard_controls;
